import './Components.css'
const FaixaInform =()=>{
    return (
        <div className="faixaInt">
         <div className="first">
             <p className="numero1">15</p>
             <p className="dado1">anos de experiência</p>
         </div>
         <div className="second">
             <p className="numero2">5000</p>
             <p className="dado2">clientes atendidos</p>
         </div>
         <div className="third">
             <p className="numero3">2</p>
             <p className="dado3">pós graduações</p>
         </div>
        </div>
    )
}
export default FaixaInform;