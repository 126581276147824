const Sobre =()=>{
    return(
        <div className="sobre">
            <h1>sobre mim</h1>
            <h2>
          'Ha mais de 15 anos atendendo como dentista em Copacabana (Rio de Janeiro)
          me expecializei em protese dentaria e colocação de facetas,se está procurando um dentista para mudar o seu sorriso de uma vez por todas,
          eu Dr Rodrigo Damasceno e minha equipe estamos de braços abertos esperando para te atender.'
            </h2>
            </div>
    )
}
    export default Sobre;
