import RedesSociais from'./RedesSociais';
const TagContato =()=>{
    return(
      <div className='tagContato'>
        <h1 className='enderecoTag'>Praça Nilo Peçanha, 76 - sala 304 - Centro Barra do Pirai</h1>
        <div className='divRedes'><RedesSociais/></div>
       
      </div>
    )

}
export default TagContato;