import './Components.css'
import RedesSociais from './RedesSociais';
const Header=()=>{
    return(
 <div className='cabecalho'>

  <div>
     <h1 className='nome'>Rodrigo Damasceno odontologia</h1>
  </div>

  
 </div>

)
}
export default Header;