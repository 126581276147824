import './Components.css'
import Whats from './Whats';
const FaleConosco=()=>{
    return(
        <div className="Fixed">

         <div className="faleConosco"><Whats/></div>
         
         
        </div>
    )
}
export default FaleConosco;